import React, { useEffect, useState } from "react";
import { AllCartItems } from "../components/organisms/AllCartItems/AllCartItems";
import { PageTitle, SEO } from "../modules/seo";

const Cart = () => {
  const [checkoutId, setCheckoutId] = useState<string | null>(null);

  useEffect(() => {
    setCheckoutId(localStorage.getItem("checkoutId"));
  }, []);

  return (
    <div
      style={{
        position: "relative",
        zIndex: 3,
      }}
    >
      <SEO title={PageTitle.Cart} />
      {checkoutId && <AllCartItems cart checkoutId={checkoutId} />}
    </div>
  );
};
export default Cart;
